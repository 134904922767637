import React from 'react'
import './ParameterStyles.css'

const Parameter = () => {
  return (
    <div name="of" className='product'>
        <div className="container">
            <div className="content">
                <h2><span>Parametry płyty</span>TErO </h2>
                <p>Płyty TErO są produkowane w dwóch dostępnych grubościach: <br/>

TErO 10 - 10 x 1200 x 2200 mm<br/>
TErO 20 - 20 x 1200 x 2200 mm<br/>
 

Płyty TErO cechuje wodoodporność więc można stosować je w warunkach dużego zawilgocenia lub w bezpośrednim kontakcie z wodą.<br/>


Powyższe porównanie zrobiono na podstawie wyników badań wykonanych przez Instytut Techniki Budowlanej w Warszawie.<br/>

Wyniki badań dowodzą, że poza modułem sprężystości, płyty TErO mają zdecydowaną przewagę nad produktami konkurencyjnymi. Szczególnie godny podkreślenia jest fakt ponad 3-5 krotnie większej odporności płyt TErO na wilgoć niż produkty konkurencyjne.</p>
            </div>

       
        </div>
    </div>
  )
}

export default Parameter