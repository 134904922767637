import { Image } from "react-grid-gallery";

export const images: Image[] = [
  {
    src: "https://i.imgur.com/J0gk3G5.jpg",
    width: 320,
    height: 174,
  },
  {
    src: "https://i.imgur.com/lsFLOZf.jpg",
    width: 320,
    height: 212,
  },
  {
    src: "https://i.imgur.com/aCOttTs.jpg",
    width: 320,
    height: 212,
  },
  {
    src: "https://i.imgur.com/nk8bAHA.jpg",
    width: 320,
    height: 213,
  },
  {
    src: "https://i.imgur.com/4VWPqgz.jpg",
    width: 240,
    height: 320,
  },
  {
    src: "https://i.imgur.com/BUHjxQF.jpg",
    width: 320,
    height: 190,
  },
  {
    src: "https://i.imgur.com/iELIo31.jpg",
    width: 320,
    height: 148,
  },
  {
    src: "https://i.imgur.com/nXpHOlu.jpg",
    width: 320,
    height: 213,
  },
  
];
