import React from 'react'
import './ProductStyles.css'


const Product = () => {
  return (
    <div name="ot" className='product'>
        <div className="container">
            <div className="content">
                <h2><span>Nasz </span>Produkt </h2>
                <div className="flex">
                <p>Płyta TErO to innowacyjna płyta pomocnicza (szalunkowa) - produkt który łączący w sobie poniższe cechy:<br/>

- innowacyjny technologicznie wyrób ekologiczny,<br/>

- produkt powstający w 100% w procesie przetwarzania odpadów tj. w procesie recyklingu,<br/>

- produkt w 100% wodoodporny,  <br/>

- produkt o dużej gęstości i małej sprężystości oraz o wysokich parametrach wytrzymałościowych,<br/>

- wyrób w 100% recyklingowalny,<br/>

- produkt w 100% wytworzony w Polsce z odpadów krajowych.<br/>

 

Wymienione wyżej łączne zalety płyty TErO odróżniają ją od innych produktów konkurencyjnych o podobnym przeznaczeniu.

Technologia produkcji płyt TErO jest opracowaniem własnym producenta zastrzeżonym w Urzędzie Patentowym.

Płyty TErO to praktyczne wdrożenie zasad Corporate Social Responsibility (Społecznej Odpowiedzialności Biznesu). Kupując płyty TErO wspierasz recykling materiałowy w Polsce!</p>

            
            </div>
          </div>
        </div>
    </div>
  )
}

export default Product