import React from 'react'
import './OpisStyles.css'

const Opis = () => {
  return (
    <div name="op" className='about'>
        <div className="container">
            <div className="content">
                <h2><span>Opis płyty </span>TErO</h2>
                <p>Płyty TErO znajdują zastosowanie jako ekologiczne płyty pomocnicze w budownictwie , w tym przede wszystkim jako płyty szalunkowe wielokrotnego użycia (można je stosować do 8-miu razy). Ponadto płyty doskonale się nadają jako podbitki i wykończenia dekarskie, parkany budowlane odporne na deszcz i słońce. Mogą być też używane w przemyśle meblarskim lub jako spody przy wykonywaniu podług w środkach transportu (np. przyczepach, kontenerach). Płyty TErO można również stosować jako izolację przeciwwilgociową lub jako wykończenie ścian pomieszczeń technicznych.  Z płyt TErO można wykonywać palety i skrzynie transportowe, kraty a także akcesoria dekoracyjne.</p>
            </div>
        </div>
    </div>
  )
}

export default Opis