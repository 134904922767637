import React from 'react'
import { BsFillArrowUpCircleFill } from 'react-icons/bs'
import './FooterStyles.css'
import { FiFacebook, } from 'react-icons/fi'

import {Link} from 'react-scroll'

const Footer = () => {
  return (
    <div className='footer'>
      <div className="container">
        <div className="top">
          <div className="logo-footer">
            <img src="https://i.imgur.com/5ly2ttd.png" alt="" className='icon' />
            <h2>TErO</h2>
          </div>
          <Link activeClass="active" to="top" spy={true} smooth={true} duration={500}>
            <BsFillArrowUpCircleFill className='icon'/>
          </Link> 
        </div>
        <div className="col-container">
          <div className="col">
            <h3>Nawigacja</h3>
            <p><Link activeClass="active" to="top" spy={true} smooth={true} duration={500}>Strona główna</Link></p>
            <p><Link activeClass="active" to="as" spy={true} smooth={true} duration={500}>O nas</Link></p>
            <p><Link activeClass="active" to="ot" spy={true} smooth={true} duration={500}>Nasz produkt</Link></p>
            <p><Link activeClass="active" to="op" spy={true} smooth={true} duration={500}>Opis</Link></p>
            <p><Link activeClass="active" to="of" spy={true} smooth={true} duration={500}>Parametry</Link></p>
            <p><Link activeClass="active" to="oe" spy={true} smooth={true} duration={500}>Zastosowania</Link></p>
          </div>
          <form>
            <h3>Skontaktuj sie z nami</h3>
            <p>PMP RECYKL <br/>

ul. Sandomierska 270<br/>

27-440 Ćmielów<br/>

Tel:<br/>

535 365 635 ㅤSławomir     ㅤ  s.borek@pmprecykl.com<br/>

504 439 199 ㅤRadosław     ㅤ r.guzik@pmprecykl.com<br/>

508 138 850 ㅤMałgorzata  ㅤbiuro@pmprecyk.com<br/>

<a href='https://formfortero.000webhostapp.com'>Email: tero@pmprecykl.com</a><br/></p>
            <div className="social-group">
              <a href="https://www.facebook.com/profile.php?id=100066461245031&locale=pl_PL"><FiFacebook className='social-icon'/></a>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Footer