import React from 'react'
import './plytaStyles.css'

const plyta = () => {
  return (
    <div name="oe" className='about'>
        <div className="container">
            <div className="content">
                <h2><span>Zastosowanie płyt </span>TErO</h2>
                <p>Jesteśmy jedynym polskim producentem ekologicznych i wodoodpornych płyt TErO od ponad 10-ciu lat. Swoją działalność związaną z przetwarzaniem i odzyskiem odpadów zapoczątkowaliśmy jednak dużo wcześniej bo już w roku 2003 jako mała mikro rodzinna firma, z potencjałem. Nasza firma mieści się w województwie świętokrzyskim w niewielkiej miejscowości o nazwie Ćmielów. Mimo naszych mikro rozmiarów jesteśmy w stanie dołożyć wszelkich starań aby nasz produkt TErO był konkurencyjny cenowo i jak najlepszy jakościowo. Nasza działalność jest proekologiczna ze względu na produkcję płyt z materiałów pochodzących z recyklingu. Naszym atutem jest energooszczędna  i zeroemisyjna technologia przetwarzania odpadów która odbywa się bez negatywnego odziaływania na tereny sąsiadujące i jest przyjazna dla środowiska naturalnego. Nasi pracownicy są w stanie zaoferować dobre ceny, najwyższą jakość obsługi, indywidualne podejście do każdego klienta oraz miłą i profesjonalną atmosferę. Dokładamy wszelkich starań, aby nasze produkty i usługi były wykonywane solidnie i terminowo.</p>
               
            </div>
        </div>
    </div>
  )
}

export default plyta