import React from 'react';
import Hero from './components/hero/Hero';
import Navbar from './components/navbar/Navbar';
import About from './components/about/About';
import Product from './components/product/Product';
import Footer from './components/footer/Footer';
import Opis from './components/opis/Opis';
import Parameter from './components/Parameter/Parameter';
import Plyta from './components/plyta/plyta'
import Gallery  from './components/gallery/gallery';

function App() {
  return (
    < >
      <Navbar/>
      <Hero/>
      <About />
      <Product />
      <Opis />
      <Parameter />
      <Plyta />
      <Gallery />
      <Footer />
    </>
  );
}

export default App;
